import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationContext } from "~/context/NotificationContext";
import { env } from "~/env.mjs";
import { validateEmail, validatePassword } from "~/utils";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import Loading from "../ui/Loading/Loading";
import Modal from "../ui/Modal/Modal";
import { type AuthModalType, type EmailAuthData } from "./AuthModals";
import { type AuthResponseError, type LoginResponse } from "./types";

interface FormFields {
  email: string;
  password: string;
  passwordConfirm: string;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  openModal: (type: AuthModalType, data: EmailAuthData) => void;
}

const RegisterModal: React.FC<Props> = ({ open, setOpen, openModal }) => {
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = useCallback(
    async ({ email, password, passwordConfirm }: FormFields) => {
      setIsLoading(true);

      await axios
        .post<LoginResponse>(
          `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/email/otp`,
          {
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          showNotification(
            "A one-time password (OTP) has been sent to your email. Please check your inbox.",
            "success"
          );

          openModal("confirm", {
            email: email,
            password: password,
            passwordConfirm: passwordConfirm,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const error: AuthResponseError = err?.response?.data || {};

          showNotification(
            error?.message ||
              error?.errorMessage ||
              "Unable to send OTP to your email. Please verify your email address and try again.",
            "error"
          );
        });

      setIsLoading(false);
    },
    [openModal, showNotification]
  );

  useEffect(() => {
    if (!open) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal title="Register" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
        <div className="w-72 space-y-4 text-base text-light-100 md:w-96">
          <div className="space-y-2">
            <label>Email:</label>
            <Input
              type="text"
              placeholder="Email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email address is required.",
                },
                validate: (email) =>
                  validateEmail(email) || "Please enter a valid email address.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.email?.message}
            </span>
          </div>
          <div className="space-y-2">
            <label>Password:</label>
            <Input
              type="password"
              placeholder="Password"
              {...register("password", {
                required: { value: true, message: "Password is required." },
                validate: (password) =>
                  validatePassword(password) ||
                  "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and special characters.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.password?.message}
            </span>
          </div>
          <div className="space-y-2">
            <label>Confirm password:</label>
            <Input
              type="password"
              placeholder="Confirm password"
              {...register("passwordConfirm", {
                required: { value: true, message: "Password is required." },
                validate: (passwordConfirm) =>
                  passwordConfirm === getValues("password") ||
                  "Confirm password does not match.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.passwordConfirm?.message}
            </span>
          </div>
        </div>

        <div className="flex-reverse flex">
          <Button type="submit" width={"full"} disabled={isLoading}>
            {isLoading ? <Loading size={"small"} /> : "Register"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RegisterModal;
