import Button from "~/components/ui/Button/Button";

import Image from "next/image";
import React, { useState } from "react";
import Modal from "~/components/ui/Modal/Modal";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useWaxWallet } from "~/context/WaxWalletContext";

import { SUPPORTED_WALLET } from "~/context/WaxWalletContext";
import useUser from "~/lib/useUser";
import { useVerifyWaxWallet } from "../../../data/useVerifyWaxWallet";

export const WaxWalletSelector: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  zIndex?: string;
}> = ({ isOpen, setIsOpen, zIndex }) => {
  const { loginWithWax, loginWithAnchor } = useWaxWallet();

  const handleConnect = async (type: SUPPORTED_WALLET) => {
    if (type === SUPPORTED_WALLET.WAX) {
      await loginWithWax().then(() => {
        setIsOpen(false);
      });
    }

    if (type === SUPPORTED_WALLET.ANCHOR) {
      await loginWithAnchor().then(() => {
        setIsOpen(false);
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      title="Choose Wallet"
      subtitle="Select Wallet you want to connect"
      zIndex={zIndex}
    >
      <>
        <div className="inline-flex w-full items-center justify-start">
          <Image
            src="/images/wallets/mycloudwallet.svg"
            alt="rfox-id-mycloudwallet"
            width={28}
            height={28}
            className="mr-4"
          />
          <button
            type="button"
            className="font-sans text-base text-light-50"
            onClick={async () => {
              await handleConnect(SUPPORTED_WALLET.WAX);
            }}
          >
            My Cloud Wallet
          </button>
        </div>
        <div className="inline-flex w-full items-center justify-start">
          <Image
            src="/images/wallets/anchor.svg"
            alt="rfox-id-anchor"
            width={28}
            height={28}
            className="mr-4"
          />
          <button
            type="button"
            className="font-sans text-base text-light-50"
            onClick={async () => {
              await handleConnect(SUPPORTED_WALLET.ANCHOR);
            }}
          >
            Anchor
          </button>
        </div>
      </>
    </Modal>
  );
};

const WAXWallet: React.FC<{
  showConnect?: boolean;
}> = ({ showConnect = false }) => {
  const {
    isConnected,
    walletAddress,
    logout: disconnectWaxWallet,
  } = useWaxWallet();

  const [isOpen, setIsOpen] = useState(false);

  const { user } = useUser();

  const { onVerifyWaxWallet } = useVerifyWaxWallet();

  return (
    <>
      <WaxWalletSelector isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="inline-flex gap-2">
        <Button
          width={"full"}
          intent={"secondary"}
          uppercase={false}
          outline={isConnected ? false : true}
          onClick={() => {
            if (!isConnected) {
              setIsOpen(true);
            }
          }}
        >
          {isConnected || user?.waxAddress ? (
            showConnect ? (
              <>
                <Image
                  src="/images/wax-logo.svg"
                  alt="rfox-id-wax"
                  width={40}
                  height={15}
                  className="mr-3 h-auto w-auto"
                />
                WAX Wallet
              </>
            ) : (
              <>
                <span>{walletAddress ?? user?.waxAddress}</span>

                {isConnected && user?.waxAddress === walletAddress && (
                  <>
                    <CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" />
                    <span className="ml-1 text-green-500">Verified</span>
                  </>
                )}

                {!isConnected && user?.waxAddress && (
                  <>
                    <CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" />
                    <span className="ml-1 text-green-500">Verified</span>
                  </>
                )}
              </>
            )
          ) : (
            <>
              <Image
                src="/images/wax-logo.svg"
                alt="rfox-id-wax"
                width={40}
                height={15}
                className="mr-3 h-auto w-auto"
              />
              WAX Wallet
            </>
          )}
        </Button>
        {isConnected && (
          <>
            <Button
              width="minLength"
              uppercase={false}
              intent={"secondary"}
              onClick={disconnectWaxWallet}
              size="small"
            >
              Disconnect
            </Button>
            {isConnected && user?.waxAddress !== walletAddress && (
              <Button
                width="minLength"
                uppercase={false}
                onClick={onVerifyWaxWallet}
              >
                Verify
              </Button>
            )}
          </>
        )}

        {!isConnected && !!user?.waxAddress && (
          <Button
            width="minLength"
            uppercase={false}
            intent={"secondary"}
            onClick={() => {
              setIsOpen(true);
            }}
            size="small"
          >
            Connect
          </Button>
        )}
      </div>
    </>
  );
};

export default WAXWallet;
