import { useCallback, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import ResetPasswordModal from "./ResetPasswordModal";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export type AuthModalType = "login" | "register" | "confirm" | "reset";

export type EmailAuthData = {
  email: string;
  password: string;
  passwordConfirm?: string;
  code?: string;
};

const AuthModals: React.FC<Props> = ({ open, setOpen }) => {
  const [openModal, setOpenModal] = useState<AuthModalType>("login");
  const [modalData, setModalData] = useState<EmailAuthData>();

  const openModel = useCallback((type: AuthModalType, data?: EmailAuthData) => {
    setOpenModal(type);
    setModalData(data);
  }, []);

  return (
    <>
      <LoginModal
        open={open && openModal === "login"}
        setOpen={(open) => {
          setOpen(open);
          !open && setOpenModal("login");
        }}
        openModal={openModel}
      />

      <RegisterModal
        open={open && openModal === "register"}
        setOpen={(open) => {
          setOpen(open);
          !open && setOpenModal("login");
        }}
        openModal={openModel}
      />

      <ConfirmModal
        open={open && openModal === "confirm"}
        authData={modalData}
        setOpen={(open) => {
          setOpen(open);
          !open && setOpenModal("login");
        }}
      />

      <ResetPasswordModal
        open={open && openModal === "reset"}
        setOpen={(open) => {
          setOpen(open);
          !open && setOpenModal("login");
        }}
      />
    </>
  );
};

export default AuthModals;
