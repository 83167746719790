import axios from "axios";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationContext } from "~/context/NotificationContext";
import { env } from "~/env.mjs";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import Loading from "../ui/Loading/Loading";
import Modal from "../ui/Modal/Modal";
import { type EmailAuthData } from "./AuthModals";
import { type AuthResponseError, type LoginResponse } from "./types";

interface FormFields {
  code: string;
}

interface Props {
  open: boolean;
  authData?: EmailAuthData;
  setOpen: (open: boolean) => void;
}

const ConfirmModal: React.FC<Props> = ({ open, authData, setOpen }) => {
  const { push } = useRouter();
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = useCallback(
    async ({ code }: FormFields) => {
      setIsLoading(true);

      await axios
        .post<LoginResponse>(
          `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/email/register`,
          {
            email: authData?.email ?? "",
            password: authData?.password ?? "",
            passwordConfirm: authData?.passwordConfirm ?? "",
            code: code,
          }
        )
        .then(async (res) => {
          const data = res.data;

          await push(
            `/auth/callback?token=${data.accessToken}&refreshToken=${data.refreshToken}&playfabId=${data.playfabId}&sessionTicket=${data.playfabSessionTicket}`
          ).then(() => console.log("redirected"));
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const error: AuthResponseError = err?.response?.data || {};

          showNotification(
            error?.message ||
              error?.errorMessage ||
              "Registration failed. Please check your details and try again.",
            "error"
          );
        });

      setIsLoading(false);
    },
    [authData, push, showNotification]
  );

  useEffect(() => {
    if (!open) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal title="Confirmation Code" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
        <div className="w-72 space-y-4 text-base text-light-100 md:w-96">
          <div>Check your email for the confirmation code</div>
          <div className="space-y-2">
            <Input
              type="text"
              placeholder="Code"
              {...register("code", {
                required: {
                  value: true,
                  message: "Confirmation code is required.",
                },
                validate: (code) => /^[0-9]+$/g.test(code) || "Invalid OTP",
              })}
            />
            <span className="text-xs text-red-500">{errors.code?.message}</span>
          </div>
        </div>

        <div className="flex-reverse flex">
          <Button type="submit" width={"full"} disabled={isLoading}>
            {isLoading ? <Loading size={"small"} /> : "Confirm"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ConfirmModal;
