import axios from "axios";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationContext } from "~/context/NotificationContext";
import { env } from "~/env.mjs";
import { validateEmail, validatePassword } from "~/utils";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import Loading from "../ui/Loading/Loading";
import Modal from "../ui/Modal/Modal";
import { type AuthModalType } from "./AuthModals";
import { type AuthResponseError, type LoginResponse } from "./types";

interface FormFields {
  email: string;
  password: string;
  remember: boolean;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  openModal: (type: AuthModalType) => void;
}

const LoginModal: React.FC<Props> = ({ open, setOpen, openModal }) => {
  const { push } = useRouter();
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const [rememberedEmail, setRememberedEmail] = useState<string>("");

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = useCallback(
    async ({ email, password, remember }: FormFields) => {
      setIsLoading(true);

      if (remember) {
        localStorage.setItem("remembered-email", email);
      } else {
        setRememberedEmail("");
        localStorage.removeItem("remembered-email");
      }

      await axios
        .post<LoginResponse>(
          `${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/email/login`,
          {
            email: email,
            password: password,
            remember: remember,
          }
        )
        .then(async (res) => {
          const data = res.data;

          await push(
            `/auth/callback?token=${data.accessToken}&refreshToken=${data.refreshToken}&playfabId=${data.playfabId}&sessionTicket=${data.playfabSessionTicket}`
          ).then(() => console.log("redirected"));
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const error: AuthResponseError = err?.response?.data || {};

          // clear password field
          reset({ email: email, password: "", remember: remember });

          showNotification(
            error?.message ||
              error?.errorMessage ||
              "Login failed. Please check your credentials and try again.",
            "error"
          );
        });

      setIsLoading(false);
    },
    [push, reset, showNotification]
  );

  useEffect(() => {
    if (!open) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const email = localStorage.getItem("remembered-email");
    if (email) setRememberedEmail(email);
  }, []);

  return (
    <Modal title="Login" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
        <div className="w-72 space-y-4 text-base text-light-100 md:w-96">
          <div className="space-y-2">
            <label>Email:</label>
            <Input
              type="text"
              placeholder="Email"
              defaultValue={rememberedEmail}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email address is required.",
                },
                validate: (email) =>
                  validateEmail(email) || "Please enter a valid email address.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.email?.message}
            </span>
          </div>
          <div className="space-y-2">
            <label>Password:</label>
            <Input
              type="password"
              placeholder="Password"
              {...register("password", {
                required: { value: true, message: "Password is required." },
                validate: (password) =>
                  validatePassword(password) ||
                  "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and special characters.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.password?.message}
            </span>
          </div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <input
                id="remember"
                type="checkbox"
                defaultChecked={!!rememberedEmail}
                {...register("remember")}
                className="h-4 w-4 rounded border-gray-300 text-primary-100 focus:ring-primary-100"
              />
              <label htmlFor="remember" className="test-sm">
                Remember me
              </label>
            </div>
            <div>
              <button
                type="button"
                className="text-primary-100"
                onClick={(e) => {
                  e.preventDefault();
                  openModal("reset");
                }}
              >
                Forgot Password?
              </button>
            </div>
          </div>
        </div>

        <div className="flex-reverse flex">
          <Button type="submit" width={"full"} disabled={isLoading}>
            {isLoading ? <Loading size={"small"} /> : "Login"}
          </Button>
        </div>

        <div className="text-center text-sm">
          Don&apos;t have an account?{" "}
          <button
            type="button"
            className="text-primary-100"
            onClick={(e) => {
              e.preventDefault();
              openModal("register");
            }}
          >
            Create Account
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default LoginModal;
