import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useState, type ReactElement } from "react";
import AuthModals from "~/components/auth/AuthModals";
import { Container } from "~/components/layout/Container";
import Layout from "~/components/layout/Layout";
import Button from "~/components/ui/Button/Button";
import EVMWallet from "~/components/wallet/evm/EVMWallet";
import WAXWallet from "~/components/wallet/wax/WaxWallet";
import { NotificationContext } from "~/context/NotificationContext";
import { env } from "~/env.mjs";
import fetchJson from "~/lib/fetchJson";
import useUser from "~/lib/useUser";
import { AuthenticationService } from "~/type/Auth";
import { copyToClipboard, getLoginUrl } from "~/utils";

const Home = () => {
  const { user, mutateUser } = useUser();
  const userLoggedIn = user?.id && user?.isLoggedIn && user?.userConsent;
  const router = useRouter();
  const { redirect } = router.query;
  const { showNotification } = useContext(NotificationContext);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const logout = async () => {
    await mutateUser(
      await fetchJson("/api/auth/logout", { method: "GET" }),
      false
    );
  };

  useEffect(() => {
    if (user?.id && !user.userConsent) {
      void logout();
    }
  }, [user?.id, user?.userConsent]);

  const handleCopy = async (text: string) => {
    await copyToClipboard(text).then(() => {
      showNotification(
        "The content has been successfully copied to your clipboard.",
        "success"
      );
    });
  };

  return (
    <>
      <Head>
        <title>RFOX VALT | Login</title>
      </Head>
      <Container>
        <div className="Container relative mx-auto mt-20 flex h-screen w-full justify-center">
          <div className="center absolute mx-4 h-fit items-center rounded-lg bg-dark-200 px-10 py-10 md:px-16 md:py-16">
            <h3 className="text-center text-2xl font-bold italic leading-6 text-light-50 md:text-4xl">
              Connect Your Account
            </h3>
            <p className="mt-2 max-w-2xl text-center text-sm text-light-250 md:mt-4  md:text-base">
              Login/ Sign up to RFOX ID With Your Favorite Social Media Account
            </p>

            <Image
              src="/images/rfox-id.svg"
              alt="rfox-id"
              width={366}
              height={106}
              className="mx-auto mt-6 w-28"
            />

            {userLoggedIn ? (
              <div className="mx-auto flex w-fit flex-col justify-center">
                <div className="mb-4 flex gap-2 text-xs font-semibold text-light-200">
                  {user?.playfabId ? user.playfabId : user?.id}
                  <DocumentDuplicateIcon
                    className="h-4 w-4 cursor-pointer text-light-200 hover:text-primary-100"
                    onClick={async () => {
                      await handleCopy(
                        user?.playfabId ? user.playfabId : user?.id
                      );
                    }}
                  />
                </div>

                <Button intent={"primary"} onClick={logout}>
                  Logout
                </Button>
              </div>
            ) : (
              <div className="mx-auto mt-2 flex flex-row justify-center gap-4">
                <Link
                  className="flex items-center justify-center rounded-md bg-white p-2 md:px-8"
                  href={getLoginUrl(
                    AuthenticationService.GOOGLE,
                    redirect?.toString()
                  )}
                >
                  <Image
                    src="/images/google.svg"
                    alt="rfox-id-google"
                    width={20}
                    height={20}
                    className="mx-auto h-7 w-7"
                  />
                </Link>
                {env.NEXT_PUBLIC_FACEBOOK_LOGIN && (
                  <Link
                    className="flex items-center justify-center rounded-md bg-blue-500 p-2 md:px-8"
                    href={getLoginUrl(AuthenticationService.FACEBOOK)}
                  >
                    <Image
                      src="/images/facebook.svg"
                      alt="rfox-id-facebook"
                      width={20}
                      height={20}
                      className="mx-auto h-7 w-7"
                    />
                  </Link>
                )}
                <Link
                  className="flex items-center justify-center rounded-md bg-white p-2 md:px-8"
                  href={getLoginUrl(
                    AuthenticationService.APPLE,
                    redirect?.toString()
                  )}
                >
                  <Image
                    src="/images/apple.svg"
                    alt="rfox-id-apple"
                    width={20}
                    height={20}
                    className="mx-auto h-7 w-7"
                  />
                </Link>
                {env.NEXT_PUBLIC_EMAIL_LOGIN && (
                  <button
                    onClick={() => setOpenLoginModal(true)}
                    className="flex items-center justify-center rounded-md bg-white p-2 px-8"
                  >
                    <EnvelopeIcon className="h-7 w-7 text-dark-200" />
                  </button>
                )}
              </div>
            )}

            {userLoggedIn && (
              <div className="mx-auto mt-4 flex max-w-md flex-col gap-2">
                <div className="inline-flex w-full items-center justify-center">
                  <hr className="my-8 h-px w-full border-0 bg-light-250" />
                  <span className="absolute left-1/2 -translate-x-1/2 bg-dark-200 px-3 font-medium text-light-250 ">
                    Link your wallets
                  </span>
                </div>

                <EVMWallet />

                <WAXWallet />

                <Link href="/profile">
                  <Button width={"full"} intent={"primary"}>
                    Continue
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>

      <AuthModals open={openLoginModal} setOpen={setOpenLoginModal} />
    </>
  );
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout publicAccess={true}>{page}</Layout>;
};

export default Home;
