import { useContext } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { NotificationContext } from "~/context/NotificationContext";
import fetchJson, { type FetchError } from "~/lib/fetchJson";
import useUser from "~/lib/useUser";
import { type User } from "~/pages/api/auth/user";

export const useVerifyEthWallet = () => {
  const { mutateUser } = useUser();
  const { signMessageAsync } = useSignMessage();
  const { showNotification } = useContext(NotificationContext);
  const { address } = useAccount();

  const verifyWallet = async (
    address: string,
    message: string,
    signature: string
  ) => {
    if (address) {
      console.log("address", JSON.stringify({ address, message, signature }));
      await fetchJson<User>("/api/auth/update-eth-address", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ address, message, signature }),
      })
        .then((user) => {
          void mutateUser(user);
          showNotification(
            "Your wallet address has been successfully verified.",
            "success"
          );
        })
        .catch((error: FetchError) => {
          showNotification(error.data.message, "error");
        });
    }
  };

  const onVerifyWallet = async () => {
    if (!address) {
      showNotification(
        "Please connect to the wallet you are attempting to verify.",
        "error"
      );
      return;
    }

    const message = `Verify your Ethereum address for RFOX VALT to connect with RfoxID. This will enable you to use your NFTs within the RFOX VALT. \nEthereum Address: ${address}`;

    try {
      const signature = await signMessageAsync({
        message,
      });

      console.log("signature before sending to be", { signature, message });
      await verifyWallet(address.toString(), `${message}`, signature);
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
        return;
      }
      showNotification(
        "Verification of your address failed. Please try again.",
        "error"
      );
    }
  };

  return {
    onVerifyWallet,
  };
};
