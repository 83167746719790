import { type Connector, useAccount, useConnect, useDisconnect } from "wagmi";
import Button from "~/components/ui/Button/Button";

import Image from "next/image";
import React, { useEffect } from "react";
import Modal from "~/components/ui/Modal/Modal";
import { truncateAddress } from "~/utils";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useVerifyEthWallet } from "~/data/useVerifyEthWallet";
import useUser from "~/lib/useUser";

export const EVMWalletSelector: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  zIndex?: string;
}> = ({ isOpen, setIsOpen, zIndex }) => {
  const { connectors, connectAsync, status, error, reset } = useConnect();

  const handleConnect = async (
    connector: Connector<unknown, unknown, unknown>
  ) => {
    await connectAsync({
      connector,
    }).then(() => {
      setIsOpen(false);
    });
  };

  const errorName = error?.name;
  useEffect(() => {
    if (error?.name === "ConnectorNotFoundError") {
      window.open("https://metamask.io/download/", "_blank");
    }
  }, [errorName]);

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      title="Choose Wallet"
      subtitle="Select Wallet you want to connect"
      zIndex={zIndex}
    >
      {status === "loading" ? (
        <div className="animate-pulse">Connecting...</div>
      ) : status === "error" ? (
        <>
          <div>{error?.message}</div>
          <Button onClick={reset}>Retry</Button>
        </>
      ) : (
        <>
          <div className="inline-flex w-full items-center justify-start">
            <Image
              src="/images/wallets/metamask.svg"
              alt="rfox-id-metamask"
              width={28}
              height={28}
              className="mr-4"
            />
            <button
              type="button"
              className="font-sans text-base text-light-50"
              onClick={async () => {
                await handleConnect(
                  connectors[0] as Connector<unknown, unknown, unknown>
                );
              }}
            >
              Metamask
            </button>
          </div>
          <div className="inline-flex w-full items-center justify-start">
            <Image
              src="/images/wallets/wallet-connect.svg"
              alt="rfox-id-wallet-connect"
              width={28}
              height={28}
              className="mr-4"
            />
            <button
              type="button"
              className="font-sans text-base text-light-50"
              onClick={async () => {
                await handleConnect(
                  connectors[1] as Connector<unknown, unknown, unknown>
                );
              }}
            >
              WalletConnect
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const EVMWallet: React.FC<{ showConnect?: boolean }> = ({ showConnect }) => {
  const { address, isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useUser();
  const { onVerifyWallet } = useVerifyEthWallet();

  const handleDisconnect = async () => {
    await disconnectAsync();
  };

  return (
    <>
      <EVMWalletSelector isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="inline-flex gap-2">
        <Button
          width={"full"}
          intent={"secondary"}
          uppercase={false}
          outline={isConnected ? false : true}
          onClick={() => {
            if (!isConnected) {
              setIsOpen(true);
            }
          }}
        >
          {showConnect ? (
            <>
              <Image
                src="/images/ethereum-logo.svg"
                alt="rfox-id-eth"
                width={15}
                height={24}
                className="mr-3 w-auto"
              />
              <span>Ethereum</span>
            </>
          ) : isConnected || user?.ethAddress ? (
            <>
              {(address || user?.ethAddress) && (
                <span>
                  {truncateAddress(
                    (address ? address : user?.ethAddress) as string,
                    3
                  )}
                </span>
              )}

              {isConnected &&
                user?.ethAddress?.toLowerCase() === address?.toLowerCase() && (
                  <>
                    <CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" />
                    <span className="ml-1 text-green-500">Verified</span>
                  </>
                )}

              {!isConnected && !!user?.ethAddress && (
                <>
                  <CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" />
                  <span className="ml-1 text-green-500">Verified</span>
                </>
              )}
            </>
          ) : (
            <>
              <Image
                src="/images/ethereum-logo.svg"
                alt="rfox-id-eth"
                width={15}
                height={24}
                className="mr-3 w-auto"
              />
              <span>Ethereum</span>
            </>
          )}
        </Button>
        {isConnected && (
          <>
            <Button
              width="minLength"
              uppercase={false}
              intent={"secondary"}
              onClick={handleDisconnect}
              size="small"
            >
              Disconnect
            </Button>
            {user?.ethAddress?.toLowerCase() !== address?.toLowerCase() && (
              <Button
                width="minLength"
                uppercase={false}
                onClick={onVerifyWallet}
              >
                Verify
              </Button>
            )}
          </>
        )}

        {!isConnected && !!user?.ethAddress && (
          <Button
            width="minLength"
            uppercase={false}
            intent={"secondary"}
            onClick={() => {
              setIsOpen(true);
            }}
            size="small"
          >
            Connect
          </Button>
        )}
      </div>
    </>
  );
};

export default EVMWallet;
