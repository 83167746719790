import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

const InputStyles = cva(
  "block w-full flex-1 rounded-md bg-dark-300 text-white focus:border-light-300 border focus:ring-light-300 disabled:bg-dark-250 disabled:text-dark-50 sm:text-sm",
  {
    variants: {
      variant: {
        normal: "border-dark-100",
        error: "border-primary-100",
      },
      iSize: {
        small: "",
        medium: "px-4 py-3",
        large: "px-6 py-4",
      },
    },
    defaultVariants: {
      variant: "normal",
      iSize: "small",
    },
  }
);
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof InputStyles> {
  icon?: React.ReactNode;
  ref?: React.ForwardedRef<HTMLInputElement>;
}

const Input: React.FC<InputProps> = forwardRef(
  (
    { variant, iSize, icon, ...props },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return icon ? (
      <div className="group flex items-center rounded-md border border-dark-50 bg-dark-300 pl-2 pr-1 focus-within:ring-1 focus-within:ring-light-300">
        {icon}
        <input
          {...props}
          className={`border-0 focus:ring-0 ${InputStyles({
            variant,
            iSize,
          })}`}
          ref={ref}
        />
      </div>
    ) : (
      <input {...props} className={InputStyles({ variant, iSize })} ref={ref} />
    );
  }
);

Input.displayName = "Input";

export default Input;
