import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationContext } from "~/context/NotificationContext";
import { env } from "~/env.mjs";
import { validateEmail } from "~/utils";
import Button from "../ui/Button/Button";
import Input from "../ui/Input/Input";
import Loading from "../ui/Loading/Loading";
import Modal from "../ui/Modal/Modal";
import { type AuthResponseError } from "./types";

interface FormFields {
  email: string;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ResetPasswordModal: React.FC<Props> = ({ open, setOpen }) => {
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = useCallback(
    async ({ email }: FormFields) => {
      setIsLoading(true);

      await axios
        .post(`${env.NEXT_PUBLIC_PORTAL_API_URL}/api/v1/auth/email/recovery`, {
          email: email,
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          showNotification(
            "Instructions for resetting your password have been sent to your email.",
            "success"
          );

          setOpen(false);
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const error: AuthResponseError = err?.response?.data || {};

          showNotification(
            error?.message ||
              error?.errorMessage ||
              "Unable to send a password reset email. Please check your email address and try again.",
            "error"
          );
        });

      setIsLoading(false);
    },
    [setOpen, showNotification]
  );

  useEffect(() => {
    if (!open) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal title="Reset password" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-8">
        <div className="w-72 space-y-4 text-base text-light-100 md:w-96">
          <div className="space-y-2">
            <label>Email:</label>
            <Input
              type="text"
              placeholder="Email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Email address is required.",
                },
                validate: (email) =>
                  validateEmail(email) || "Please enter a valid email address.",
              })}
            />
            <span className="text-xs text-red-500">
              {errors.email?.message}
            </span>
          </div>
        </div>

        <div className="flex-reverse flex">
          <Button type="submit" width={"full"} disabled={isLoading}>
            {isLoading ? <Loading size={"small"} /> : "Reset password"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ResetPasswordModal;
