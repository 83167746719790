import { type TransactArgs } from "anchor-link";
import { createHash } from "crypto";
import { type Transaction } from "ethers";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { NotificationContext } from "~/context/NotificationContext";
import fetchJson, { type FetchError } from "~/lib/fetchJson";
import useUser from "~/lib/useUser";
import { type User } from "~/pages/api/auth/user";
import { useWaxWallet } from "../context/WaxWalletContext";

export const useVerifyWaxWallet = () => {
  const { mutateUser } = useUser();
  const { walletAddress, signTransaction } = useWaxWallet();
  const { showNotification } = useContext(NotificationContext);

  const verifyWallet = async (address: string) => {
    if (address) {
      await fetchJson("/api/auth/update-wax-address", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ address }),
      })
        .then((user) => {
          void mutateUser(user as User);
          showNotification(
            "Your wallet address has been successfully verified.",
            "success"
          );
        })
        .catch((error: FetchError) => {
          showNotification(error.data?.message, "error");
        });
    }
  };

  const onVerifyWaxWallet = async () => {
    const hash = createHash("sha256");
    hash.update(uuidv4());
    const nonce = hash.digest("base64");

    if (!walletAddress) return;

    const transaction: Transaction | TransactArgs = {
      actions: [
        {
          account: "atomictoolsx",
          name: "auth",
          authorization: [
            {
              actor: walletAddress,
              permission: "active",
            },
          ],
          data: {
            nonce,
          },
        },
      ],
    };

    try {
      const signedData = await signTransaction(transaction, false);

      if (signedData) {
        await verifyWallet(walletAddress);
        return;
      }
    } catch (error) {
      console.error("Failed to verify", error);
    }
    //showNotification("Failed to verify your wallet address", "error");
  };

  return {
    onVerifyWaxWallet,
  };
};
