import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { classNames } from "~/utils";

export interface Props {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  zIndex?: string;
}

const Modal: React.FC<Props> = ({ ...props }) => {
  const { open, setOpen, title, subtitle, zIndex } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          "fixed inset-0 overflow-y-auto",
          zIndex ? zIndex : "z-10"
        )}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-6 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative my-8 inline-block w-auto max-w-sm transform overflow-hidden rounded-3xl border border-dark-100 bg-dark-200 p-6 pb-12  text-left align-middle shadow-xl transition-all md:max-w-screen-2xl">
              <div className="flex flex-row justify-between">
                <div className="text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-light-100"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-0">
                    <p className="text-sm text-light-250">{subtitle}</p>
                  </div>
                </div>
                <button className="ml-10">
                  <XMarkIcon
                    className="h-5 w-5"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </button>
              </div>
              <div className="mt-8 flex flex-col gap-8">{props.children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
